import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import HeaderHome from './HeaderHome';
import ApplyNowForm from './ApplyNowForm';


export default function GenAI() {
   const [modalShow, setModalShow] = useState(false);

   const handleModalShow = () => {
      setModalShow(true);
   }

   const handleModalClose = () => {
      setModalShow(false);
   }

   return (
      <React.Fragment>
         <HeaderHome />
         <Helmet>
            <meta charset="UTF-8" />
            <title>Simplify Your Tech Hiring Needs With Skillible</title>
            <meta name="description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
            <link rel="canonical" href="http://skillible.io/" />
            <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
            <meta property="og:description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
            <meta property="og:url" content="http://skillible.io/" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="Skillible" />
         </Helmet>
         <div>
            <section className="banner-home position-relative home-slider-skil gen-ai-banner">
               <div className="" >
                  <div className="item">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 text-center">
                              <div className="text-banner">
                                 <img alt="" src={require(`../assets/images/partner-logo.png`)} />
                                 <h2> Are You Ready for the Future? </h2>
                                 <h4> <span>Gen AI</span> With Skillible - The Change Begins!</h4>
                                 <p>
                                    Catapult your career into the future of AI- Master Generative AI with Skillible's immersive program. Build real-world apps, learn from industry experts, and join the AI revolution.
                                 </p>
                                 <h5> <span>Start Date -</span> July 15, 2024  | <span>Duration- </span>  06-Weeks </h5>
                                 <button>
                                    <Link to="#" onClick={handleModalShow} >
                                       Enroll Now </Link>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="sec-genai-enroll mb-4">
               <div className="container">
                  <div className="row ltr-rtln1">
                     <div className="col-md-6">
                        <div className="text-right enroll-genai-head">
                           <span><span ><span ><img alt="" aria-hidden="true" src="" /></span>
                              <img alt="" src={require(`../assets/images/cus-img.png`)} /></span></span>
                           <div className="enroll-gen-">
                              <div className="enroll-gen-div">
                                 <div className="genai-enroll"> <Link to='#' onClick={handleModalShow} >Enroll  <span>
                                    Now
                                 </span></Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="text-left resimeExListbg mt-4">
                           <div className="resimeExList ">
                              <span className="img"><span ><span >
                              </span>
                                 <img alt="" src={require(`../assets/images/enroll.png`)} /></span></span>
                              <div className="genai-eligibility">
                                 <h3>Who Can Enrol?</h3>
                                 <p>Learners from both Hindi and English backgrounds are welcome to enroll in this course and take advantage of its bilingual format.   </p>
                              </div>
                           </div>
                           <div className="resimeExList">
                              <span className="img"><span >
                                 <span>
                                 </span>
                                 <img alt="" src={require(`../assets/images/proficiency.png`)} /></span></span>
                              <div className="genai-eligibility">
                                 <h3> Minimum Eligibility  </h3>
                                 <p>Open to all backgrounds, whether technical or non-technical, this revolutionary course welcomes everyone to participate and upskill.   </p>
                              </div>
                           </div>
                           <div className="resimeExList">
                              <span className="img"><span ><span >
                              </span>
                                 <img alt="" src={require(`../assets/images/learn.png`)} /></span></span>
                              <div className="genai-eligibility">
                                 <h3>What Will You learn? </h3>
                                 <p>The curriculum, curated for industry relevance, is delivered by distinguished faculty and industry experts through a blend of videos, case studies, hands-on projects, and interactive live online sessions. </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="course-nutshell position-relative">
            <img className="nutshel-banner" alt="" src={require(`../assets/images/banner/nutshel-bg.png`)} />
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center genai-heading">
                        <h2>
                           Experience Our Unmatchable Course In A Nutshell
                        </h2>
                     </div>
                     <div className="col-md-12 col-lg-5">
                        <div className="genai-circulm">
                           <div className="gen-circulm2 gen-circulm">
                              <div className="genai-img"> <span >
                                 <img alt="" src={require(`../assets/images/icon01.png`)} /></span>
                              </div>
                              <span className="genNum">1</span>
                              <div className="gentex">A Curriculum covering from theory to hands-on-project. </div>
                           </div>
                           <div className="gen-circulm2 gen-tx">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon3_1.png`)} /></span>
                              </div>
                              <span className="genNum">2</span>
                              <div className="gentex">Get Skilled through real project </div>
                           </div>
                           <div className="gen-circulm2 gen-tx">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon1.png`)} /></span>
                              </div>
                              <span className="genNum">3</span>
                              <div className="gentex">Get Skilled In the latest Gen AI Tools</div>
                           </div>
                           <div className="gen-circulm2 gen-circulm4">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon4.png`)} /></span>
                              </div>
                              <span className="genNum">4</span>
                              <div className="gentex"> Special Masterclass by Industry Experts</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-7 text-right">
                        <div className="thikstpbg">
                           <div className="thikstp thikstp1">
                              <div className="th-Text">
                                 <div className="th-Text1"><span>60 </span> Hours of learning  <span className="img">
                                    <img src={require(`../assets/images/48.png`)} className="img-fluid" alt="" /></span>
                                 </div>
                              </div>
                           </div>
                           <div className="thikstp thikstp2">
                              <div className="th-Text">
                                 <div className="th-Text1"> Get Trained on Real Time  </div>
                                 <div className="th-Text2"> Industry Projects </div>
                              </div>
                               <div className="thikstpimg"><span>
                                  <img src={require(`../assets/images/available.png`)} className="img-fluid" alt="" /> 
                                 </span>
                              </div> 
                           </div>
                           <div className="thikstp thikstp4">
                              <div className="th-Text1"><span>Explore - </span>   7+GenAI Tools <span>
                                 <img src={require(`../assets/images/circlprstg.png`)} className="img-fluid" alt="" /></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services-industry genai-heading-2">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center hding2 b-to-a-course">
                        <h2>A Skill-Focused  Curriculum</h2>
                        <p>Our curriculum isn't merely instructed by academics; it's enriched and directed by leading industry experts. Designed to immerse you in the realm of Gen AI from its very foundations, our program acknowledges the critical demand for promptness coveted by employers today. Through meticulous curation, we've crafted a module empowering every tech graduate to master the latest trend 'Prompt Engineering,' and emerge as a formidable asset across diverse domains. </p>
                     </div>
                  </div>
               </div>
            </section>
            <section className="section course-objective">
               <div className="container course mt-2">
                  <div className="piece-content2">
                     <div className="clearfix">
                        <div className="heading-text">
                           <h2> Our Curriculum covers the most In-Demand Topics :</h2>
                        </div>
                     </div>
                     <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Module 1: Introduction to Generative AI
                              </button>
                           </h2>
                           <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> This introductory module unlocks the secrets of Generative AI (Gen AI)! Explore its history and key figures, then dive into core concepts and applications. Learn how Gen AI works, from text generation to image creation. Master essential terms like GANs and prompts. Finally, discover the future potential and exciting career opportunities in Gen AI.  </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Module 2: Basic Fundamentals of Generative AI
                              </button>
                           </h2>
                           <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> This foundational module sets the stage for your Gen AI exploration. We'll break down the essential building blocks: Machine Learning and Deep Learning, the brains behind AI's creative power. We'll also delve into Natural Language Processing (NLP) and Computer Vision, uncovering how AI "sees" and interprets information. Finally, you'll be introduced to Generative Models like GANs and VAEs, understanding how they create entirely new content. We'll even explore the distinction between Generative and Discriminative models. By the end, you'll have a solid grasp of the core concepts that fuel Gen AI.  </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Module 3: Terminologies and Concepts
                              </button>
                           </h2>
                           <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Let's dive deeper! Master key terms like sampling and loss functions, the language of Gen AI. Unlock the power of prompts - learn how to craft them for specific tasks, maximizing your creative control. Finally, get hands-on with popular Gen AI platforms like Bard and ChatGPT, putting your knowledge into action! </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree"> Module 4: Mastering Prompt Engineering
                              </button>
                           </h2>
                           <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> This will transform you into a prompt-wielding master! Learn diverse prompt styles – informative, creative, directive – to guide your AI creations. Craft prompts for specific tasks like summarizing text, generating code, or writing stories. We'll delve into keywords, phrases, and tones, allowing you to fine-tune prompts for the results you desire. Explore using examples and references to give the AI context. We'll also identify common mistakes to avoid and teach you how to iterate and refine prompts based on feedback. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfive">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">Module 5:  Generative Tasks
                              </button>
                           </h2>
                           <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Explore a range of mind-blowing Generative AI tasks. Craft captivating stories, poems, or emails with Text-to-Text generation. Need visuals? Transform text into stunning images or breathe life into stories with Text-to-Video animation. Compose music, sound effects, even voiceovers with text prompts! Feeling artistic? Morph images or create videos from static pictures. We'll even explore cutting-edge possibilities like code generation. Get hands-on with real-world tools, learn to evaluate outputs, and discover real-world applications. Get ready to unleash your inner AI artist!  </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingsix">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Module 6: Understanding Limitations and Biases in Generative AI Models
                              </button>
                           </h2>
                           <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> This module will equip you to use Generative AI responsibly by exploring its limitations, biases, and copyright considerations. We'll address unethical issues like deepfakes, misinformation, and privacy concerns, offering strategies for ethical AI use. Additionally, you will learn to understand the ethical impacts and detect AI-generated content for a responsible future with Generative AI. </div>
                           </div>
                        </div>
                      
                     </div>
                  </div>
               </div>
            </section>
            <section className="services-industry2">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-md-6 text-center ps-0">
                        <div className="imglindbg"><span className="line-img1">
                           <img src={require(`../assets/images/line-img1.png`)} className="img-fluid" alt="" /></span>
                           <span className='img-genai-bg'><img src={require(`../assets/images/img_text.png`)} className="img-fluid" alt="background-  Skillible" />
                              <img src={require(`../assets/images/img_text1.png`)} className="img-fluid img_text1" alt="Rapid
                        Doubt Resolution - Skillible "/></span>
                        </div>
                     </div>
                     <div className="col-md-6 text-center pe-0">
                        <div className="we-respnbg">
                           <div className="we-respnhding xyt-dc">
                              <h2> Round The Clock Support By <span> Skillible </span></h2>
                           </div>
                           <div className="text-end we-respond-img round-clock">
                              <img src={require(`../assets/images/10min.png`)} className="img-fluid" alt="Resolve your Doubt with Skillible" />
                           </div>
                           <div className="team-onlinlist">
                              <ul className='round-clock-support'>
                                 <li>
                                    <span className="icon"><i className="fas fa-check"></i></span>
                                    <div className="">Guidance By Industry Experts  </div>
                                 </li>
                                 <li>
                                    <span className="icon"><i className="fas fa-check"></i></span>
                                    <div className="">Practical Learning</div>
                                 </li>
                                 <li>
                                    <span className="icon"><i className="fas fa-check"></i></span>
                                    <div className="">Quick Doubt Resolution Forum </div>
                                 </li>

                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="faqScen faqScenbg mb-0 pt-5 pb-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="mb-4 mt-4"> Frequently Asked Questions </h2>
                     </div>
                     <div className="bg_tabsfaq1">
                        <div className="row">
                           <div className="col-md-3">
                               <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                 <button className="nav-link active mt-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a1_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- General Questions" />
                                       <img src={require(`../assets/images/a1_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- General Questions" />
                                    </span> 
                                    <span>General Questions</span>
                                 </button>
                                 <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a2_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- Fees & Income" />
                                       <img src={require(`../assets/images/a2_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- Fees & Income" />
                                    </span> 
                                    <span> Fees & Income </span>
                                 </button>
                                 <button className="nav-link mb-0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a3_black.png`)} className="img-fluid img1" alt="Skillible- Admission Queries" />
                                       <img src={require(`../assets/images/a3_white.png`)} className="img-fluid img2" alt="Skillible- Admission Queries" />
                                    </span> 
                                    <span>Admission Queries </span>
                                 </button>
                              </div> 
                           </div>
                           <div className="col-md-9">
                              <div className="tab-content general_questions" id="v-pills-tabContent">
                                 <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="general-s">
                                       <h3>General Questions</h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item mb-4" >
                                                <h2 className="accordion-header" id="heading91">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse91" aria-expanded="true" aria-controls="collapse91"> How long does the course last?   </button>
                                                </h2>
                                                <div id="collapse91" className="accordion-collapse collapse show" aria-labelledby="heading91" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p> The course lasts a maximum of 6 weeks (about 1 and a half months).
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading93">
                                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse93" aria-expanded="false" aria-controls="collapse93"> Is this course available for offline participation?   </button>
                                                </h2>
                                                <div id="collapse93" className="accordion-collapse collapse" aria-labelledby="heading93" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p> No, this course is exclusively online.  </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="general-s">
                                       <h3> Fees & Income  </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> Does this course have a fee?  </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>
                                                         No, there are no enrollment fees for this course.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="general-s">
                                       <h3>Application Questions </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item mb-4">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">  Is this course available for working professionals?  </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>While our current focus is on colleges, we're open to considering working professionals for our course. There are currently no restrictions in place, and we're exploring opportunities to accommodate a broader audience. Feel free to reach out if you're interested! </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading2">
                                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">  Is this course offered on a full-time or part-time basis?   </button>
                                                </h2>
                                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <ul>
                                                         <li>This course is offered on a full-time basis. </li>
                                                      </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {modalShow && <ApplyNowForm show={modalShow} hide={handleModalClose} />}
         </div>
      </React.Fragment>
   )
}