import React from 'react'
import HeaderHome from './HeaderHome';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default function About() {

  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Skillible - We Help You Succeed In Your Career!</title>
          <meta name="description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
          <meta property="og:description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />
        </Helmet>
      </HelmetProvider>
      <div>
        <section className="industries-banner she-leads">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner hire-tr-td">

                  <h1>Skillible Nurtures The Spirit Of <span> Equality </span>  And <span> Diversity </span> In The Tech Ecosystem. </h1>
                  <p className="banner-para3">  We pledge to create a
                    workforce that is highly robust and increasingly tech-enabled. We aim to achieve greater
                    socio-economic mobility for all sections of society by reimagining the role of technology
                    in delivering corporate advantage and upskilled career advantage.</p>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner pair-imgdv ndvt-2">
                  <span className="pair-img"><img src={require(`../assets/images/pairw.png`)} className="img-fluid" alt="" /></span>
                  <div className="pairbanner_girl">
                    <img src={require(`../assets/images/aboutd.png`)} className="img-fluid" alt="Skillible trains and upskills individuals in the tech domain" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-6">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/about-t.png`)} className="img-fluid" alt="We design complete learning solutions for Top IT roles - Skillible" />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1">
                  <h2>What We <span className="day-one-skil">Do ?</span> </h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">We focus on performance and help the businesses and individuals to perform better. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">We develop and streamline the employee onboarding process by using the HTD model.   </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">We hire and train fresh graduates and give them the opportunity to get placed with top IT companies. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">We design complete learning solutions for Top IT roles.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">We allow our learners to acquire new skills and sharpen the existing ones.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">We deliver complete and affordable solutions to our clients by offering them access to the best talent pool available in the tech ecosystem.   </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">We give more power to women professionals by helping them to get back on their feet </div>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext hd2 ndvt-3">
          <div className="container">
            <div className="row displ-lrtd mx-wth">
              <div className="col-md-12 col-lg-6 text-end">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/about-t1.png`)} className="img-fluid" alt="How we started- Skillible " />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 ">
                  <h2>Our Voyage</h2>

                  <p>Skillible is an emerging Ed-Tech brand launched in 2022. It was designed to reciprocate the rising needs of hiring leaders and fulfilling the enlarged skill gap prevailing among the learners.
                  </p>
                  <p>
                    Our ambition was to create a train of action that would help our hiring partners to lay their hands-on well-versed
                    <span className="deplyo-one-skil" > experienced IT talents </span> in unison with creating a pool of proficient skilled workforce.    </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="skillible-s-sec mt-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="ourfamliy-text2d missin-head our-vision-mis">
                  <h2>Our Mission </h2>
                  <div className="skillible-list">
                    <p className="vertical-left">
                      Our goal is to maximize our performance not just in terms of figures but with respect to our clients and learners’ satisfaction.  Our intent is not just to ensure equality and diversity in every workspace, but also to assist every female professional in making a spectacular comeback in her career.
                    </p>
                    <h2>Our Vision </h2>
                    <p className="vertical-left2">
                      We envision a tech ecosystem where learning is developed through thoughtful and successful application of technology.  We believe in increasing the learner-centricity and vocationality of education. Through our virtual labs, we are helping our learners to emerge as future tech experts. Any enterprise that wishes to work with us and streamline its hiring needs will benefit from our tech-enabled learning methods just as much as our learners.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <img
                    src={require(`../assets/images/mission-vission.png`)}
                    className="img-fluid"
                    alt="Skillible mission is to maximize learners' and partners' satisfaction and envision a tech ecosystem that fulfills hiring needs."
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="she-equipped-worsx">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2> OUR INTEGRATED APPROACH:
                    <span> (Towards Our Valuable Clients & learners)</span> </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-6">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/clients.png`)} className="img-fluid" alt="Skillible offers customized talents at zero hiring cost " />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 day-dep-ts">
                  <h2>Skilled developers are not just available on
                    <span className="day-one-skil"> job boards!</span>
                  </h2>
                  <p>Your search for skilled IT resources can only be attained through mediums that believe in
                    <span className="deplyo-one-skil" > practicing transparency and delivering quality </span>
                    training and education. Skillible can assist you through various mediums and sources.</p>
                  <p>
                    We not just offer customized training solutions but also give you the opportunity to hire trained professionals
                    or freshers at zero hiring cost. Along with this, you'll get the opportunity to reduce employee benching costs,
                    lower down higher attrition rates, and get access to productive resources from the first day of deployment. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext hd2 ndvt-3">
          <div className="container">
            <div className="row displ-lrtd mx-wth">
              <div className="col-md-12 col-lg-6 text-end">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/leaners.png`)} className="img-fluid" alt="Skillible offers technical skills that offers great market value " />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 ">
                  <h2>Skillible creates a candidate experience
                    <span className="day-one-skil"> worth bragging about</span>
                  </h2>
                  <p>With Skillible you can learn the most in-demand technical skills. These technical skills are filled with great potential and market value. We ensure that all of our courses are accessible to all segments of society. Not only working women but also those who have taken a career break. Not only professionals but also young graduates can fulfill their ambition to be a part of the tech ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="meet-peaple team">
          <div className="container">
            <div className="col-md-12 text-center hd2">
              <div className="">
                <h2>Leadership Team</h2>
                <p>The community of intellectually-driven individuals who share a common goal of transforming the now,
                  and the future.
                </p>
              </div>
            </div>
            <div className="meet-peaple-bdr">
              <div className="col-md-12 text-center hd2 mb-4">
                
              </div>
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet meet-peaplet3 text-center">
                      <div className="meet-peaple-img">
                        <span
                        ><img
                            src={require(`../assets/images/ankur-circle.png`)}
                            className="img-fluid"
                            alt="Ankur Goel - GROUP CEO - Skillible"
                          /></span>
                      </div>
                      <div className="meet-peaple-text">
                        <h3>Ankur Goel</h3>
                        <p className="postion-leader"> GROUP CEO</p>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet meet-peaplet3 text-center">
                      <div className="meet-peaple-img">
                        <span
                        ><img
                            src={require(`../assets/images/varun-circle.png`)}
                            className="img-fluid"
                            alt="Varun Mahajan - CO-FOUNDER AND MANAGING PARTNER - Skillible"
                          /></span>
                      </div>
                      <div className="meet-peaple-text">
                        <h3>Varun Mahajan</h3>
                        <p className="postion-leader"> CO-FOUNDER & <br/> MANAGING PARTNER</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet meet-peaplet3 text-center">
                      <div className="meet-peaple-img">
                        <span
                        ><img
                            src={require(`../assets/images/vikash-circle.png`)}
                            className="img-fluid"
                            alt="Vikas Gupta - GROUP CTO - Skillible"
                          /></span>
                      </div>
                      <div className="meet-peaple-text">
                        <h3>Vikas Gupta</h3>
                        <p className="postion-leader"> GROUP CTO</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet mt-3 meet-peaplet3 text-center">
                      <div className="meet-peaple-img">
                        <span
                        ><img
                            src={require(`../assets/images/sunaina-circle.png`)}
                            className="img-fluid"
                            alt=" Sunaina Agarwal - CHRO - Skillible"
                          /></span>
                      </div>
                      <div className="meet-peaple-text">
                        <h3>Sunaina Agarwal</h3>
                        <p className="postion-leader"> CHRO</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet mt-3 meet-peaplet3 text-center">
                      <div className="meet-peaple-img">
                        <span
                        ><img
                            src={require(`../assets/images/suchita-circle.png`)}
                            className="img-fluid"
                            alt=" Suchita Vishnoi - CMO"
                          /></span>
                      </div>
                      <div className="meet-peaple-text">
                        <h3>Suchita Vishnoi</h3>
                        <p className="postion-leader"> CMO</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet mt-3 meet-peaplet3 text-center">
                      <div className="meet-peaple-img">
                        <span
                        ><img
                            src={require(`../assets/images/Apurva-Shrivastava-circle.png`)}
                            className="img-fluid"
                            alt=" Apurva Shrivastava - Co Founder and CBO"
                          /></span>
                      </div>
                      <div className="meet-peaple-text">
                        <h3>Apurva Shrivastava</h3>
                        <p className="postion-leader">  CO-FOUNDER & <br/> CBO</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </React.Fragment>
  )
}