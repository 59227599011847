import React from 'react'
import HeaderHome from './HeaderHome'
export default function TermsOfUse({ isModal }) {
   return (
      <React.Fragment>
         {isModal === undefined ? <>
            <HeaderHome />
            <section className="banner-home-contact contactUsBanner">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="text-banner-contact">
                           <h1>Skillible - Terms Of Use</h1>
                           <p>This Terms Of Use Will Guide .....</p>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </> : ""}
         <section className="conatct-section contact-formsce contact-formsce1">
            <div className="container">
               <div className="row">
                  <div className="col-mc-12">
                     <div className="bg_contactForm">
                        <div className="row">

                           {isModal !== true && <div className="col-md-12 pb-4 text-start hding2">
                              <h2>Terms <span>of Use</span>
                              </h2>
                           </div>}
                           <div className="col-md-12 termsuse">
                              <p className=""> These Terms & Conditions ("Terms") are between Skillible (owned and maintained by Neerinfo Solutions Private Limited) and its users ("User/You/Your"). They apply to both (a) the website www.skillible.io ("Website"), our applications ("Platform"), and any products or services related to the Application/Website/Products ("Services"), as well as (b) any modes of registrations or usage of products, including through SD cards, tablets, or other storage/transmitting devices.   </p>
                              <p className="mb-4"> In accordance with the provisions of the Information Technology Act of 2000 and its implementing Information Technology (Intermediaries Guidelines) Rules of 2011, as amended from time to time, these Terms constitute an electronic record. </p>
                              <p className="mb-4"> Before using the Application, Website, Services, or Products, please carefully read the Terms and the Company's privacy policy ("Privacy Policy") with regard to registration with us, the use of the Application, Website, Services, and Products. The clauses of the Terms shall take precedence over any other policies with regard to the Application, Website, Services, or Products in the event of any conflict.  </p>
                              <p className="mb-4"> Any use, access, browsing, or registration (with or without payment/with or without subscription) of the Application or Website, or usage of the Services or Products, shall constitute Your acceptance of the Terms and Your agreement to be bound thereby. Please refrain from using the application, website, platform, or using the services if you disagree with the terms or the privacy statement. Any registration or subscription-based access to our services, applications, websites, or platforms is non-transferable.  </p>
                              <h2>
                                 <span>1. Eligibility</span>
                              </h2>
                              <p>The Website, Application, Platform, and Services may only be used by individuals who are allowed to enter into legally binding contracts under Indian law. Only those who are 18 years old or older are allowed to use the website, application, platform, and services. Additionally, individuals whose membership has been suspended or cancelled by Skillible for any reason whatsoever are not permitted to access the Site, Application, or Services. Any use or access by anyone under the age of 18 of the website, application, platform, and services is explicitly banned. By accessing or using the Site, Application or Services you affirm and guarantee that you are 18 years or older.
                              </p>
                              <h2>
                                 <span>2. Changes to the Terms</span>
                              </h2>
                              <p>We retain the unilateral right, at any time, without prior notice to you and at our sole discretion, to change the terms of the website. Any changes or modifications to these Terms or Policy will be posted on this website, and will take effect as of the day the user’s responsibility to periodically/regularly review this website's Terms the user’s responsibility to periodically/regularly review this website's Terms the updated Terms are uploaded. Therefore, it is the user’s responsibility to periodically/regularly review the Terms of this website. Users will be deemed to have accepted the updated Terms if they continue to use the Website after they have been updated.</p>
                              <h2>
                                 <span>3. Changes to the Website</span>
                              </h2>
                              <p>We may occasionally update the website and change the content, appearance, or form without prior notification in order to optimize and enhance the user experience. We do not guarantee that the Website, any provided content, displayed content, or any forms will indeed be error- or omission-free. Furthermore, neither the Website nor its content must be updated by us. </p>
                              <h2>
                                 <span>4. User Responsibilities</span>
                              </h2>
                              <p>
                                 By using the Skillible platform, you acknowledge that all content, whether publicly or privately provided,
                                 including text, software, code, music, sound, photographs, graphics, video, or other materials ("Content"),
                                 is the sole responsibility of the user or the account holder. You understand that our platform may
                                 expose you to content that could be objectionable or offensive. We shall not be liable
                                 for any content on the platform or for any errors or omissions.
                              </p>
                              <p> You explicitly agree, when using our platform or any provided service, not to: </p>

                              <ol className='temslist'>
                                 <li> Provide or engage in any conduct that is unlawful, illegal, threatening, harmful, abusive, harassing, stalking,
                                    tortious, defamatory, libelous, vulgar, obscene, offensive, objectionable, pornographic, designed to or does interfere
                                    with the platform or any service provided, infected with a virus or other destructive or deleterious programming routine,
                                    giving rise to civil or criminal liability, or which may violate an applicable local, national, or
                                    international law. </li>
                                 <li> Impersonate or misrepresent your association with any person or entity, or forge or otherwise seek to conceal or misrepresent the origin of any content provided by you.</li>
                                 <li> Collect or harvest any data about other users.</li>
                                 <li> Use the platform and any content or service for commercial purposes or in any manner that would involve junk mail, spam, chain letters, pyramid schemes, or any other form of unauthorized advertising without our prior written consent.</li>
                                 <li>Provide any content that may give rise to our civil or criminal liability or which may constitute or be considered a violation of any local, national, or international law, including but not limited to laws relating to copyright, trademark, patent, or trade secrets.</li>
                                 <li> Use course content for any purpose other than learning within Skillible. Courses can only be accessed by you through your Skillible account. No part of the Skillible Courses may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods.</li>
                              </ol>
                             <h2>
                                 <span>5. Terms of Services</span>
                              </h2>
                              <ol className='temslist'>
                                 <li>We consider that any person who uses our application, services, platform, or website should be able to give informed permission before submitting any information needed to utilize the application, services, platform, or website. You expressly consent to the collection, processing, storage, disclosure, and management of your information as outlined in our Privacy Policy as it is currently written and as we may alter it by registering with us. Your information will be processed in India in any way, including but not limited to gathering, storing, erasing, using, combining, sharing, transferring, and disclosing information. Your information will be transferred, processed, and kept in line with the applicable Indian data protection regulations even if you live outside of India.</li>
                                 <li> Whether or not you register as a user of the Services, by using the Application/Services/Platform/Website, you agree to abide by and be legally bound by the terms and conditions of these Terms. These Terms, which form a legally binding contract between you and Skillible, regulate your access to and use of the Site, Application, Services, and any related material. The user further consents to Skillible contacting them via call, SMS, email, and WhatsApp for the purpose of delivering current services and for the purpose of informing them of new goods and services.</li>
                                 <li> The sole purpose for which You use our Application, Services, Platform, and Website is for Your own personal, non-commercial use. It is not permitted to utilize the application, services, platform, or website for anything other than personal use. The following limitations apply to your personal and noncommercial use of this application, services, platform, and website</li>
                                 <li>  It is forbidden to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Application, our Website, our Services, and our Platform. It is also forbidden to remove any copyright, trademark registration, or other proprietary notices from any of these sources.</li>
                                 <li> You agree not to: (a) use this application, our website, platform, or any of our services for commercial purposes of any kind; (b) advertise or sell the application or any products, services, or domain names in any way (whether for profit or not); (c) solicit others (including, without limitation, through requests for money or other gifts); or (d) use any public forum for such purposes or use the Application and/or Website/our products and services in any way that is against the law, hurts the company or any other person or entity as decided by the company, or otherwise violates the terms of this agreement. Making available any information that is false, illegal, dangerous, threatening, abusive, tortuous, defamatory, libelous, vulgar, obscene, child pornographic, lewd, lascivious, profane, invading another person's privacy, hateful, or objectionable on the basis of race, ethnicity, or other factors;</li>
                                 <li> Harassing, intimidating, or stalking someone else, as well as encouraging others to use violence;</li>
                                 <li> Disseminating anything that incites someone to commit a crime, exposes them to legal liability, or otherwise violates any applicable laws, rules, or codes of conduct;</li>
                                 <li> Interfering with another user's use or enjoyment of the application, website, or services;</li>
                                 <li> Making, transmitting, or storing electronic copies of copyrighted items without the owner's consent, engaging in any behavior that constitutes intellectual property infringement, or making accessible any content that violates anyone else's intellectual property or other proprietary rights;</li>
                                 <li> Make available any content or materials that You are not authorized to make available by any law, contract, or fiduciary relationship unless You are the owner or the person in control of the rights thereto or You have obtained all required consents for such use of the content;</li>
                                 <li> Impersonate someone or something, or fraudulently claim or otherwise suggest that you are affiliated with someone or something;</li>
                                 <li> Posting, transmitting, or making available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files, or such other programs that may harm the Application/services, interests or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent;</li>
                                 <li> Access or use the application, website, services, or goods in a way that could harm, disable, overrun, or impair any server(s) on which the application or website is hosted or any network(s) connected to such a server(s);</li>
                                 <li> To deliberately or accidentally interfere with or disrupt the services, to break any laws that may be in force regarding access to or use of the Application/Website/Services/products, to transgress any requirements, procedures, policies, or regulations of networks connected to the Application/Website/Services/products, or to engage in any other activity that is prohibited by these Terms;</li>
                                 <li>  Disrupt or interfere with the security of, or otherwise harm, the Application/Website/Services/Products, materials, or system resources, or gain unauthorized access to user accounts, passwords, servers, or networks connected to or accessible through the Application/Website/Services/Products, or any affiliated or linked sites;</li>
                                 <li> Prevent any user from using and enjoying access to the application, website, services, or products, or other websites that are linked with them, or participate in disruptive activities like denial-of-service attacks against the application, website, services, or products;</li>
                                 <li> Use deep-links, page-scrape, robot, spider or any other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the Application/Website/Services/products, to access, acquire, copy or monitor any portion of the Application /Website/Services/products, or in any way reproduce or circumvent the navigational structure or presentation of the Application, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Application/ Website/Services/products;</li>
                                 <li> Modify or change any component of the Services;</li>
                                 <li> Use the Services for any purposes that are not permitted by: (i) these Terms; and (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or</li>
                                 <li> Violate any of the terms specified under the Terms for the use of the Application /Website/Services/products.</li>
                              </ol>

                              <h2>
                                 <span>6. Registration and Account </span>
                              </h2>
                              <ol className='temslist'>
                                 <li>The Site, Application, and Services can be utilized to make it easier for professionals and students to sign up for courses. Such Courses are Listed on the Website, in the Application, and in the Services. Unregistered users of the Site, Application, and Services may see Listings; however, in order to enrol in Courses, you must first register and create an account.</li>
                                 <li> You must create an account in order to use the website or utilize the services; nevertheless, guest users will only have limited access to the website. The account information won't be utilized for anything else without first getting your permission. You hereby accept responsibility for any activities that take place in your account and agree to keep your account and password secure as well as to restrict access to your computer.</li>
                                 <li> While registering, you must enter personal information about yourself, including your name, age, phone number, and location. You agree that this information will always be true and complete while using the website or using the services. Additionally, you agree to the fact that the data you have submitted is not private or secret and violates the rights of third parties.</li>
                                 <li>  If you are using, browsing, accessing, or availing services on someone’s else behalf, you represent that you have the authority to bind that person to the Terms of this website herein. In such an event website is not responsible for any wrongful act or infringement of a right or any other act whatsoever in nature, you yourself are responsible.</li>
                                 <li>  If you suspect that your account has been hacked or that there has been a security breach, you should notify the website's contact information right away. The website won't be held accountable for any violation of rights.</li>
                                 <li> If you violate any of our terms of service or if we determine, in our sole discretion, that doing so would be in Skillible's best interest, we reserve the right to terminate your account without providing you with prior notice.</li>
                              </ol>

                              <h2>
                                 <span>7. Course listings </span>
                              </h2>
                              <ol className='temslist'>
                                 <li> Skillible will create a Listing of various online Courses and the details about the Course, including, but not limited to, the subject, topic, number of sessions, mode of conduct, and time slots of the lectures and pricing and related rules and financial terms will be listed on the Site and the Application. Listings will be made publicly available via the Site, Application, and Services. You understand and agree that the placement or ranking of Listings in search results may depend on a variety of factors, including, but not limited to Student’s/Professionals preferences, ratings.</li>
                                 <li> Students/Professionals will be able to view Courses via the Site, Application and Services based upon the information provided in the Listing, Students/Professionals requirements, and their search parameters and preferences. We understand and agree that once a student/professional requests enrolment for Course. We may not request the student to pay a higher price than that mentioned in the Site or Application.</li>
                                 <li> In addition to enrolling in a course, you must upload some details, such as a profile photo or a phone number that has been verified. These requirements must be met by anyone who wants to enrol in one of the Courses.</li>
                                 <li>  Please take note that Skillible disclaims all liability for a student's or Professional's adherence to any contracts with or obligations to third parties, relevant laws, rules, or regulations. If Skillible determines, in its sole discretion, that any Student or Professional is objectionable for any reason, in violation of these Terms or Skillible's then-current Standards, Trademark & Branding Guidelines, or otherwise harmful to the Site, Application, or Services, Skillible reserves the right to remove or disable access to that Student or Professional at any time and without prior notice.</li>
                                 <li> If you are a teacher (also known as a Trainer), you acknowledge and agree that your relationship with Skillible is limited to that of a member and an independent, third-party contractor, and not, for any reason, an employee, agent, joint-venture partner, or other types of partnership. You understand and agree that you act solely on your own behalf and for your own benefit, not on behalf of or for the benefit of Skillible. Your offline activities are not under the control of Skillible. You consent to refrain from doing any actions that would give the idea that you are affiliated with, endorsed by, working with, or acting on behalf of Skillible, including improperly utilizing any of its intellectual property.</li>
                              </ol>
                              <h2>
                                 <span>8. Payment Terms </span>
                              </h2>
                              <ol className='temslist'>
                                 <li>If a student requests enrollment in a course using the site, application, or services, we must pre-approve, confirm, or reject the request within seven days of the requested date (the "Enrolment Request Period"); if not, the request will immediately expire. Any fees collected by Skillible for the requested enrolment will be returned to the concerned Student/Professional if We are unable to confirm it or determine whether to reject it within the Enrolment Request Period. Depending on the choices you make via the Site, Application, and Services, Skillible will send the Student or Professional an email, a text message, or a message via email together with the Application when we confirm an enrolment requested by them.</li>
                                 <li>Before the student or professional submits an enrolment request to Skillible, the course fees due will be disclosed to them. Following acceptance of the student's or professional's enrollment request, Skillible may, in accordance with the Payments Terms, start a pre-authorization and/or charge a small sum to the student's or professional's payment method. Depending on the choices the student or professional makes via the site and application, Skillible will refund any amounts collected in the event that a requested enrolment is cancelled (before any Lectures are delivered). If necessary, any pre-authorization of the student's or professional's payment method will also be released.</li>
                                 <li> Skillible will collect the Course Fees from Student/Professional at the time of the enrolment request.</li>
                                 <li>Skillible agrees that no refund will be permitted in respect of Lectures already provided and in accordance with the cancellation policy reflected in the relevant Listing, (i) permit the Student/Professional to cancel the enrolment and (ii) refund to the Student/Professional that portion of the Course Fees specified in the applicable cancellation policy. In case the relevant Listing does not have a specified cancellation policy, the refund amount would be pro-rata to the unconsumed portion of the tuition.</li>
                                 <li>You acknowledge and agree that Skillible may issue a system-generated invoice to the student or professional in relation to the course(s) in which the student or professional has enrolled or in relation to any type of payment made, in accordance with applicable laws, through its site or application. The invoice for the aforementioned will be raised by Skillible and will include all applicable taxes</li>
                                 <li> In connection with your Skillible Account, you as a Student/Professional agree to pay the Course Fees for each enrolment requested. The Course Fees will be collected by Skillible in accordance with the Payments Terms.</li>
                                 <li>You will receive a confirmation email describing your confirmed enrolment once your transaction for confirmed enrollment is complete.</li>
                              </ol>
                              <h2>
                                 <span>9. Privacy</span>
                              </h2>
                              <p>
                                 Registration data and other personally identifiable information
                                 collected by Skillible are governed by our Privacy Policy.
                              </p>
                              <h2>
                                 <span>10. Liability Protection</span>
                              </h2>
                              <p> By using Skillible, you agree to indemnify and protect us, our subsidiaries, affiliates, related parties, officers, directors, employees, agents, independent contractors, advertisers, partners, and co-branders from any claims, including reasonable attorney's fees, brought by any third party due to or arising from your use of the website or service, your provision of content, your violation of these Terms of Use, or any infringement of another person's rights.   </p>
                              <h2>
                                 <span>11. Warranty Disclaimer </span>
                              </h2>
                              <p> You acknowledge and accept that your use of Skillible and its services or content ("the service") is at your own risk. The service is provided to you "as is," and we explicitly disclaim all warranties, whether implied or express, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement.   </p>
                              <h2>
                                 <span>12. Intellectual Property Ownership and Rights Notices</span>
                              </h2>
                              <p>The Site, Application, Services, and Collective Content are covered by Indian laws pertaining to copyright, trademarks, and other intellectual property rights. You agree and understand that the Site, Application, Services, and Collective Content, along with all related intellectual property rights, are the sole property of Skillible and its licensors. Any copyright, trademark, service mark, or other property rights notices that are included in or accompany the site, application, services, or collective content may not be removed, altered, or obscured by the user. All Skillible trademarks and registered trademarks in India and other countries, as well as all logos, trade names, and other intellectual designations used on or in conjunction with the Site, Application, Services, and Skillible Content, are owned by Skillible. Only for the purpose of identification and possibly as the property of their respective owners, trademarks, service marks, logos, trade names, and any other proprietary designations of third parties used on or in connection with the Site, Application, Services, and Skillible Content are used on these platforms. Your use of the Site, Application, Services, and Collective Content is subject to additional terms, guidelines, and policies, including the Skillible Trademark & Branding Guidelines, which you acknowledge and agree to be governed by (as may be updated from time to time). </p>
                              <h2>
                                 <span>13. Application License</span>
                              </h2>
                              <p>Provided that you abide by these Terms, Skillible hereby grants you a limited, non-exclusive, non-transferable license to download and instal  a copy of the Application on each computer or mobile device that you own or control, with the sole purpose of using such copy of the Application for personal use. </p>
                              <h2>
                                 <span>14. Governing law and dispute resolution</span>
                              </h2>
                              <ol className='temslist'>
                                 <li> In all respects, the laws of India shall apply to the interpretation and construction of these Terms.</li>
                                 <li> The Parties shall make every effort to resolve any disagreements amicably if they should arise between them during the term of these Terms or afterward about the legality, scope, termination, application, or claimed breach of any provision of this Article or any related issue. In the case that the parties are unable to come to an amicable agreement over the disagreement or difference, they shall send it for arbitration.</li>
                                 <li> If a dispute or difference between the parties cannot be resolved amicably within 30 (Thirty) Business Days of the dispute or difference being raised, or within such extended period as the parties may agree, the dispute or difference (the "Dispute") shall be resolved by arbitration in accordance with the Rules of Indian Council of Arbitration.</li>
                                 <li>The parties will mutually designate a single arbitrator to hear the dispute. Delhi will serve as the arbitral tribunal's seat and location of conduct. The arbitrator's decision is final, binding on the parties, and enforceable against them.</li>
                                 <li> The arbitration proceedings must be conducted in English. The arbitral decision must be made in writing, in English, and it must be final in order for the Parties to abide by it.</li>
                                 <li>  Each party to the dispute or claim shall bear an equal share of the costs and expenditures of the arbitration, including the fees of the arbitral tribunal and the Indian Council of Arbitration, and each Party shall pay its own fees, disbursements, and other charges of its counsel.</li>
                              </ol>
                              <h2>
                                 <span>15. Entire agreement</span>
                              </h2>
                              <ol className='temslist'>
                                 <li> Except where they may be augmented by additional The Parties shall mutually designate a lone arbitrator to hear the dispute. Delhi will serve as the arbitration's seat and location of conduct. The arbitrator's decision will be final, enforceable against the parties, and binding on them.</li>
                                 <li> The terms of this agreement are the whole and entire understanding between you and Skillible with respect to the Site, Application, Services, Collective Content (excluding Payment Services), and any enrolments made via the Site, Application, and Services (excluding Payment Services). These Terms supersede and replace any and all prior oral or written understandings (excluding Payment Services).</li>
                              </ol>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   )
}