import React, { useState } from 'react'
import { Link } from "react-router-dom";
export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  const [isHovered, setIsHovered] = useState(false);

  const scrollToTopBtn = () => {
    window.scrollTo(0, 0)
  }
  return (
    <>
      <button onClick={scrollToTopBtn} className="scrollToTopBtn" >
        <span className="fa fa-angle-up  relative" id="btn-vibrate"></span>
      </button>
      <footer className="">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="about-us1">
                <h4>CONTACT</h4>
                <div className="email-f">
                  <ul>
                    <li>
                      <i className="fas fa-home"></i> <span> Skillible </span> <br /> Qbicals, 3rd floor, Tower A, Tapasya Corp Heights, Sector-126, Noida-201301
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <Link to="mailto:support@skillible.io"> support@skillible.io</Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="footer-link">
                <h4>
                  <span>COMPANY</span>
                </h4>
                <ul>
                  <li>
                    <Link to="/privacy-policy">
                      <i className="fas fa-angle-right"></i> Privacy Policy </Link>
                  </li>
                  <li>
                    <Link to="/terms-of-use">
                      <i className="fas fa-angle-right"></i> Terms & Conditions </Link>
                  </li>
                  <li>
                    <Link to="/virtual_internship_termsofuse">
                      <i className="fas fa-angle-right"></i> Virtual Internship T&C </Link>
                  </li>
                  <li>
                    <Link to="/career">
                      <i className="fas fa-angle-right"></i> Careers </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <i className="fas fa-angle-right"></i> About Us</Link>
                  </li>
                  <li>
                    <Link to="/sitemap">
                      <i className="fas fa-angle-right"></i> Sitemap </Link>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="footer-link">
                <h4>
                  <span>OPPORTUNITIES</span>
                </h4>
                <ul>
                  <li>
                    <Link to="/hire-from-us">
                      <i className="fas fa-angle-right"></i> Hire From Us </Link>
                  </li>
                  <li>
                    <Link to="/hire-train-deploy">
                      <i className="fas fa-angle-right"></i> HTD Solution </Link>
                  </li>
                  <li>
                    <Link to="/join-hiring-partner">
                      <i className="fas fa-angle-right"></i> Join us to hire
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <i className="fas fa-angle-right"></i> FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="get-footer-l">
                <h4>
                  <span>Get in Touch</span>
                </h4>
                <div className="join-us-on">
                  <ul>
                    <li>
                      <Link to="https://www.facebook.com/skillible" target="_blank" className="facebook">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li className='instram-icon'>
                      <Link to="https://www.instagram.com/skillible.io" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li className='twiiter'>
                      <Link to="https://x.com/skillible" target="_blank" onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}>
                        {isHovered ? <img src={require(`../assets/images/twitter-white.png`)} className="img-fluid" alt="Twitter - Skillible" /> :
                          <img src={require(`../assets/images/twitter.png`)} className="img-fluid" alt="Twitter - Skillible" />
                        }
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/company/skillible" target="_blank">
                        <i className="fab fa-linkedin"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-f">
          <p>All Rights Reserved by Skillible | Copyright &copy; 2022-{year} skillible.io</p>
        </div>
      </footer>
    </>

  )
}
