import React from 'react'
import HeaderHome from './HeaderHome';
export default function Career() {

  return (
    <React.Fragment>
      <HeaderHome />

      <section className="industries-banner she-leads">
        <div className="container">
          <div className="row">
            <div className="col-md-6">

              <div className="text-banner she-leads-s">
                <h1 className="she-leads-head"> Careers </h1>
                <h1 className="Steadily"><span> Not Just A Great Place To Work A Place To Do Great Work </span></h1>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="img-banner pair-imgdv ndvt-2">
                <span className="pair-img"><img src={require(`../assets/images/career-7.png`)} className="img-fluid" alt="" /></span>
                <div className="pairbanner_girl">
                  <img src={require(`../assets/images/career.png`)} className="img-fluid" alt="" />
                </div>


                <div className="pewilly2">
                  <div className="pewilly2-imgtext">
                    <span><img src={require(`../assets/images/image.png`)} className="img-fluid" alt="" /></span>
                    <div className="">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="caree-page">
        <div className="container">
          <div className="col-md-12 career-bg">
            <div className="career-box">
              <div className="row">
                <div className="career-uls">
                  <div className="career-dcd">
                    <h2>Full Stack <span> Developer </span>
                    </h2>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="experience-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span>Experience:</span> 2-5 years
                    </h6>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="job-description-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span>Job description: </span> As a Full Stack Developer, you will design, develop, and implement web-based software for skillible as well as test and modify new and pre-existing software.
                    </h6>
                    <p></p>
                  </div>
                  <div className="desired-skill"> <h2>Desired skills
                  </h2></div>
                  <ul>
                    <li>Experience with JS libraries and frameworks like React, Redux, Node.js, ReactJS, ExtJS. </li>
                    <li>Good understanding of HTML, CSS. </li>
                    <li>Good knowledge of Mongo DB. </li>
                    <li>Should have experience in version control systems like GIT </li>
                    <li>Design, develop, test, deploy, maintain and improve the software. </li>
                  </ul>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="">
                          <p><strong>Job ID: </strong>E220301</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end">
                        <div className="">
                          <a href="mailto:career@skillible.io?subject=Job Apply for Fullstack | Job ID: E220301" className="btn btn-default">Apply Now</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 career-bg mt-4 mb-4">
            <div className="career-box">
              <div className="row">
                <div className="career-uls">
                  <div className="career-dcd">
                    <h2>Enterprise <span> Sales Manager </span>
                    </h2>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="experience-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span> Experience: </span> 2-5 years
                    </h6>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="location-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span>Location: </span> Bangalore, Dehradun, Mumbai, Noida and Pune
                    </h6>
                    <p></p>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="qualification-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span>Qualification: </span> We are open to any qualification with equivalent work experience.
                    </h6>
                    <p></p>
                  </div>
                  <div className="desired-skill"> <h2>Desired skills
                  </h2></div>
                  <ul>
                    <li>Drive revenue through discovering, prospecting, and creating new businesses. </li>
                    <li>Responsible for B2B and B2C sales</li>
                    <li>Drive sales by developing and executing sales strategies and plans in order to achieve sales targets. </li>
                    <li>Solicit and maintain a healthy customer pipeline of Enterprises and Growth Stage Companies </li>
                    <li>Identifying new business opportunities and follow-up the defined business opportunities </li>
                    <li>Responsible for the entire sales cycle, from prospecting to closure. </li>
                    <li>Help the company effectively communicate the value proposition through accurate diagnostics, presentations and proposals </li>
                    <li>Onboard new Logos </li>
                    <li>Help with growth strategy </li>
                    <li>Work with our growth team </li>
                    <li>Interact at CXO level (decision makers) </li>
                    <li>Enterprise sales mindset and background </li>
                    <li>Should be agile </li>
                  </ul>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="">
                          <p><strong>Job ID: </strong>S220301</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end">
                        <div className="">
                          <a href="mailto:career@skillible.io?subject=Job Apply for Sales Manager | Job ID: S220301" className="btn btn-default">Apply Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 career-bg mb-4">
            <div className="career-box">
              <div className="row">
                <div className="career-uls">
                  <div className="career-dcd">
                    <h2>Content <span> Writer </span>
                    </h2>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="experience-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span> Experience: </span> 2+ years
                    </h6>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="location-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span>Location: </span> Noida
                    </h6>
                    <p></p>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="qualification-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span>Qualification: </span> Bachelor’s degree in English or Mass Communication , Experience with Technical writing , Excellent communication skills, Proficient with Microsoft Office
                    </h6>
                    <p></p>
                  </div>
                  <div className="desired-skill"> <h2>Desired skills
                  </h2></div>
                  <ul>
                    <li>Conducting extensive research on industry-related subjects in order to create original and relevant content </li>
                    <li>Content creation for blogs, articles, product descriptions, social media, technical documentation, and the company's website </li>
                    <li>Assisting the marketing team with the creation of content for ad campaigns </li>
                    <li>Proofreading content for errors and inconsistencies. </li>
                    <li>Improving the readability of existing content by editing and polishing it </li>
                    <li>To improve traffic to the company's website, conduct keyword research and use the best SEO practices </li>
                    <li>Creating headlines and body text that will grab the target audience's attention </li>
                    <li>Identifying customers' demands and recommending new content to fill in the gaps in the company's existing content </li>
                  </ul>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="">
                          <p><strong>Job ID: </strong>S220302</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end">
                        <div className="">
                          <a href="mailto:career@skillible.io?subject=Job Apply for Content Writer | Job ID: S220302" className="btn btn-default">Apply Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 career-bg mb-4">
            <div className="career-box">
              <div className="row">
                <div className="career-uls">
                  <div className="career-dcd">
                    <h2>Social Media <span> Intern </span>
                    </h2>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="experience-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span> Experience: </span> 0-1 year
                    </h6>
                  </div>
                  <div className="d-flex">
                    <div className="kenexa-field-icon">
                      <i className="job-description-icon"></i>
                    </div>
                    <h6 className="custom-text-uppercase descip-skill-s">
                      <span>Job description: </span> We're seeking a Social Media Intern for a Talent Acquisition agency and an upcoming EdTech Platform. You'll be able to grow and advance alongside the brand environment.
                    </h6>
                    <p></p>
                  </div>
                  <div className="desired-skill"> <h2>Desired skills
                  </h2></div>
                  <ul>
                    <li>Have a thorough understanding of how each social media channel operates, as </li>
                    <li>Can optimize content for maximum engagement on platforms </li>
                    <li>Can be a key player in data collecting and analysis in order to continuously enhance the company's social media strategies. </li>
                    <li>Manage content calendar on a monthly basis for social media platforms – Facebook, Twitter, Instagram, LinkedIn, YouTube. </li>
                    <li>Research and create content on topic-specific briefs which can be converted into dynamic content pieces for different social platforms. </li>
                    <li>Contribute to organic growth marketing strategies on various social media platforms. </li>
                    <li>Support the marketing team with their specific requirements. </li>
                    <li>Pitch different content tools and ideas to grow and engage with our social media audiences. </li>
                    <li>Keep up with the latest social media trends and content trends and ideate on the integration of those trends with our communication strategy. </li>
                    <li>Can analyze and optimize the ongoing search and social media campaign performance to achieve targets. </li>
                    <li>Monitor campaign delivery to ensure campaigns are running as expected and targeting objectives are met. </li>
                    <li>Provide monthly reports analyzing the performance delivered on social media platforms. (Training will be given for the same) </li>
                  </ul>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="">
                          <p><strong>Job ID: </strong>S220303</p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end">
                        <div className="">
                          <a href="mailto:career@skillible.io?subject=Job Apply for Social Media Intern
 | Job ID: S220303" className="btn btn-default">Apply Now</a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}