import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import HeaderHome from './HeaderHome';
import { Carousel } from 'react-bootstrap';


export default function Pressmedia() {
  const [data, setData] = useState([]);

  useEffect(() => {

    const getPressMediaData = async () => {
      try {
        const response = await fetch('./json/press_release.json');
        const getJsonData = await response.json();
        setData(getJsonData);
      }
      catch (error) {

      }
    }
    getPressMediaData();


  }, []);


  const showControls = data?.publics_articles?.length > 1;

  return (
    <React.Fragment>
      <HeaderHome />
      <Helmet>
        <meta charset="UTF-8" />
        <title>Simplify Your Tech Hiring Needs With Skillible</title>
        <meta name="description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
        <link rel="canonical" href="http://skillible.io/" />
        <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
        <meta property="og:description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
        <meta property="og:url" content="http://skillible.io/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Skillible" />
      </Helmet>
      <div>
        <section className="banner-home position-relative home-slider-skil press-media-banner">
          <div className="" >
            <div className="item">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="pressmedia-section keyArea">
          <div className="container">
            <div className="row">

              <div className="col-md-12 text-center press-head-h2">
                <h2> Press Releases
                </h2>
              </div>
              <div className="col-lg-3">
                <div className="press-media-icons">
                  <ul>
                    {data && data.publications?.length > 0 && data.publications.slice(0, 12).map((item, index) => {
                      return (
                        <li key={index}>
                          <span className="icon">
                            <Link to={item.publication_site} target="_blank">
                              <img src={item.logo_path} className="img-fluid" alt={item.name}></img>
                            </Link>

                          </span>
                        </li>
                      )
                    })
                    }
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="foundational-cour press-media-box">
                  <Carousel controls={showControls}
                    indicators={showControls}>
                    {data && data.publics_articles?.length > 0 && data.publics_articles.map((article, index) => {
                      return (
                        <Carousel.Item key={index * 2.3}>

                          <div className="foundationl-cources3 border-bottom press-media-img">
                            <span>
                              <img src={article.article_main_image} className="img-fluid" alt="Skillible- Media Press" />
                            </span>
                          </div>
                          <div className="foundationl-cources4 press-content">

                            <div dangerouslySetInnerHTML={{ __html: article.title }} ></div>

                            <span>
                              <img src={article.article_sub_image[0].logo_path} className="img-fluid" alt={article.name} />
                            </span>
                            <div dangerouslySetInnerHTML={{ __html: article.description }} ></div>
                            <span className="read-more-press">
                              <Link to={article.readmore_info.readmore_url}  target='_blank'> {article.readmore_info.title}...
                                {/* <img alt="" className='press-icon-gov' src="../images/icons/government-logo-header.svg"></img> */}
                                <img src={article.article_info.logo_path} className="img-fluid" alt={article.name} />
                              </Link>
                            </span>

                            <p className="press-date"> {article.date}
                            </p>

                          </div>

                        </Carousel.Item>
                      )
                    })
                    }
                  </Carousel>
                </div>

              </div>





              {/* <div className="col-lg-6">
              <div className="foundational-cour press-media-box">
                {data && data.publics_articles?.length > 0 && data.publics_articles.map((article, index) => {

                  return (
                   <div key={index*2.1}>
                      <div className="foundationl-cources3 border-bottom press-media-img">
                        <span>
                          <img src={article.article_main_image} className="img-fluid" alt="" />
                        </span>
                      </div>
                      <div className="foundationl-cources4 press-content">

                        <div dangerouslySetInnerHTML={{ __html: article.title }} ></div>

                        <span>
                          <img src={article.article_sub_image[0].logo_path} className="img-fluid" alt={article.name} />
                        </span>
                        <div dangerouslySetInnerHTML={{ __html: article.description }} ></div>

                        <span className="read-more-press">
                          <Link to="#" target='_blank'> Read more..
                          <img alt="" className='press-icon-gov' src="../images/icons/government-logo-header.svg"></img>
                          </Link>
                        </span>

                        <p className="press-date"> 12 June, 2024
                        </p>

                      </div>
                   </div>
                  )
                })
                }
</div>

              </div> */}
              <div className="col-lg-3">
                <div className="press-media-icons">

                  <ul>
                    {data && data.publications?.length > 0 && data.publications.slice(12, 24).map((item, index) => {
                      return (
                        <li key={index * 1.2}>
                          <Link to={item.publication_site} target="_blank">
                            <span className="icon">
                              <img src={item.logo_path} className="img-fluid" alt={item.name}></img>
                            </span>
                          </Link>
                        </li>
                      )
                    })
                    }
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </React.Fragment>
  )
}