import React from 'react'
import EnquireLightbox from './EnquireLightbox'
import Slider from './Slider'
import HeaderHome from './HeaderHome'
import { HelmetProvider, Helmet } from 'react-helmet-async';
export default function Home() {
   return (
      <React.Fragment>
         <HeaderHome />
         <HelmetProvider>
            <Helmet>
               <meta charset="UTF-8" />
               <title>Skillible - We Help You Succeed In Your Career!</title>
               <meta name="description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
               <link rel="canonical" href="http://skillible.io/" />
               <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
               <meta property="og:description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
               <meta property="og:url" content="http://skillible.io/" />
               <meta property="og:type" content="website" />
               <meta property="og:locale" content="en_US" />
               <meta property="og:site_name" content="Skillible" />
            </Helmet>
         </HelmetProvider>
         <EnquireLightbox />
         <Slider />
         <section className="she-equipped-wor">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 text-center">
                     <div className="hding2 colr-spanh2">
                        <h2>How <span> We </span> Help? </h2>
                     </div>
                  </div>
               </div>
            </div>
            <div className="skillible-upskill-uy" style={{ background: '#6612f0' }}>
               <div className="accordion-she">
                  <div className="tab-she she1 active" data-color="#6612f0">
                     <img src={require(`../assets/images/skill-traning.png`)} alt="Resolve your worries and upskill with Skillible" />
                     <div className="caption">
                     </div>
                  </div>
                  <div className="tab-she she2" data-color="#2f154c">
                     <img src={require(`../assets/images/upskillingplatform.png`)} alt="Fill your confidence gap with Skillible" />
                     <div className="caption">
                     </div>
                  </div>
                  <div className="tab-she she3" data-color="#04439f">
                     <img src={require(`../assets/images/training-development.png`)} alt="Learn with Salesforce Mentors" />
                     <div className="caption">
                     </div>
                  </div>
                  <div className="tab-she she4" data-color="#29a0f9">
                     <img src={require(`../assets/images/vocational-program.png`)} alt="Equal representation of women with Skillible" />
                     <div className="caption">
                     </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <section className="for-businesses">
            <div className="container-fluid">
               <div className="row">
                  <div className="businessesbg">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="businesses-imgs">
                              <div className="owl-carousel owl-theme slider-for-business dottdsn">
                                 <div className="item">
                                    <div className="row">
                                       <div className="col-md-6">
                                          <div className="businesses-text">
                                             <h3 className='aict-head3'>
                                                Igniting Innovation With Our Academic Partners
                                             </h3>
                                             <h5>
                                                At Skillible, we proudly champion the responsible and positive utilization of advanced technology for the betterment of humanity. In our relentless pursuit of technological advancement and its accessibility to society, we are honored to collaborate with <span className='aictc-partner'> AICTE </span> to turn this impactful dream into reality. Our commitment to upskill learners in cutting-edge technologies, including Gen AI, Cybersecurity, and beyond is fortified through this wholesome partnership with <span className='aictc-partner'> </span>.
                                             </h5>
                                          </div>
                                       </div>
                                       <div className='col-md-6'>
                                          <div className="busi-img">
                                             <img src={require(`../assets/images/aicte.png`)} className="img-fluid" alt="" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="item">
                                    <div className="row">
                                       <div className="col-md-6">
                                          <div className="businesses-text">
                                             <h3 className='salesforce-head3'>
                                                Igniting Innovation With Our Academic Partners
                                             </h3>
                                             <h5>
                                                Skillible, an accredited     <span className='salesforce-partner'> Salesforce </span>  Workforce Development Partner, takes immense pride in empowering women to reclaim their careers through the 'Return To Work' initiative. Through our esteemed partnership, we have supported numerous women in overcoming career breaks and re-entering the workforce with dignity and honor. Our specially curated Salesforce Development Program has enabled these women to upskill and soar to new heights in their career trajectories.
                                             </h5>
                                          </div>
                                       </div>
                                       <div className='col-md-6'>
                                          <div className="busi-img">
                                             <img src={require(`../assets/images/salesforce-partner.png`)} className="img-fluid" alt="" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}

         <section className="why-skillible">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 col-lg-5 texvst1">
                     <div className="texvst-img">
                        <img src={require(`../assets/images/3.png`)} className="img-fluid" alt="Salesforce Authorized Partner" />
                     </div>
                     <h2>
                        Learn From Us
                     </h2>
                     <div className="why-skillible2">
                        <div className="row">
                           <div className="col-md-6 col-sm-6">
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/skill-match.png`)} alt="Skillible Skill Matcher" className="img-fluid" />
                                       </span>
                                       <h3 className=''>
                                       Upskill With  <span className=''> the Best </span>
                                       </h3>
                                    </div>
                                    <p> Skillible has partnered with <span className='aictc-partner'> AICTE </span>  to unlock upskilling paths. </p>
                                 </div>
                              </div>
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/partner-why-skill.png`)} alt="Skillible Salesforce Authorized Partner" className="img-fluid" />
                                       </span>
                                       <h3> Salesforce <span> Authorized Partner </span>
                                       </h3>
                                    </div>
                                    <p> Skillible is an authorized <span className='salesforce-partner'> Salesforce </span>  Workforce Development Partner. </p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6 col-sm-6 mtsg2">
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/pre-trained.png`)} alt="Skillible Pre-Trained Developers" className="img-fluid" />
                                       </span>
                                       <h3> Pre-Trained <span> Developers </span>
                                       </h3>
                                    </div>
                                    <p> Get access to upskilled resources & spend zero amount on their training and hiring. </p>
                                 </div>
                              </div>
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/skill-why-skill.png`)} alt="Skillible Skill Validator" className="img-fluid" />
                                       </span>
                                       <h3> Skill <span> Validator </span>
                                       </h3>
                                    </div>
                                    <p> Resources are trained in more than 30+ IT skills. </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-12 col-lg-2 bg-crl-cnet">
                     <div className="cliicon1 pt-4 text-center">
                        <img src={require(`../assets/images/4.png`)} className="img-fluid" alt="Why Skillible" />
                     </div>
                     <div className="cliicon2 text-end">
                        <img src={require(`../assets/images/5.png`)} className="img-fluid" alt="" />
                     </div>
                     <div className="crl-cnettext">
                        <div className="crl-cnettext2">
                           <h2> Why Skillible <span> ? </span>
                           </h2>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-12 col-lg-5 texvst1 texvst2">
                     <div className="why-skillible2 ms-auto">
                        <div className="texvst-img">
                           <img src={require(`../assets/images/6.png`)} className="img-fluid" alt="Job Linked Programs offered by Skillible" />
                        </div>
                        <div className="row">
                           <div className="col-md-6 col-sm-6">
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/real-time.png`)} alt="Skillible Real Time Engagement" className="img-fluid" />
                                       </span>
                                       <h3> Real-Time <span> Engagement </span>
                                       </h3>
                                    </div>
                                    <p> Interactive session  to escalate learning experience </p>
                                 </div>
                              </div>
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/zero-fees.png`)} className="img-fluid" alt="Enrol @ Zero Enrolment Fees" />
                                       </span>
                                       <h3>Zero <span> Enrolment Fees </span>
                                       </h3>
                                    </div>
                                    <p>
                                       No more enrolment fees! Skillible offers accessible learning opportunities for all.
                                    </p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6 col-sm-6 mtsg2">
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/job-skill.png`)} className="img-fluid" alt="Job Linked Program" />
                                       </span>
                                       <h3> Job Linked <span> Leverage </span>
                                       </h3>
                                    </div>
                                    <p> The most in-demand IT program to boost your chances of getting hired. </p>
                                 </div>
                              </div>
                              <div className="text-fee1">
                                 <div className="text-fee-text">
                                    <div className="text-fee2">
                                       <span className="ixon">
                                          <img src={require(`../assets/images/emi-icon.png`)} className="img-fluid" alt="JD Enhancer" />
                                       </span>
                                       <h3>
                                          Open Doors  <span>
                                             to Opportunity</span>
                                       </h3>
                                    </div>
                                    <p>
                                       Unlock Advanced Tech Skills With Us: Dive into Gen AI, Cybersecurity & beyond.
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <h2 className='grow-with-skillible'> Grow With Us </h2>
                  </div>
               </div>
            </div>
         </section>
         <section className="howcan-sec pt-5">
            <span className="howcan-botrtcolor"></span>
            <div className="container">
               <div className="row">
                  <div className="col-md-7 text-left">
                     <div className="howcanText hding2 skillible-extra-ben">
                        <h2> <span className='skillible-head-2'> Don't be a bore <img src={require(`../assets/images/smily.png`)} className="img-fluid smily" alt="" />
                        </span>  Learn more and more with Skillible!
                        </h2>
                        <ul>
                           <li> The Job Market Needs You, but with the Right Skills: Bridge the Gap and Get Hired Faster with Skillible.
                           </li>
                           <li>
                              Explore our diverse curriculum and choose the course that resonates with you. We collaborate closely with key stakeholders - Industry leaders, faculties, educational institutions, and government bodies to deliver impactful upskilling/reskilling initiatives.
                           </li>
                           <li>
                              Enroll in these courses to unlock a gateway to opportunities, where you'll not only expand your academic knowledge but also gain real-world insights through hands-on experience with live projects and used cases.
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-md-5 text-center">
                     <div className="howcanText-img">
                        <img src={require(`../assets/images/skillible-bore.png`)} className="img-fluid" alt="Skillible is an authorized Salesforce Workforce Development Partner
                     " />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="opi-services-sec opi-services-sec2 sce991">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-md-6 opi-services opi-services2 text-end">
                     <div className="opi-services-img">
                        <img src={require(`../assets/images/diversity.png`)} className="img-fluid opi-comm-img" alt="Skillible promotes Diversity and Inclusion In the Tech System " />
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="opi-servicesText hding2">
                        <h2> Fostering Diversity & Empowering Growth </h2>
                        <ul className="">
                           <li>
                              <div className=""> At Skillible, we embrace diversity as a catalyst for continuous evolution. We firmly believe that inclusivity and diversity are pivotal in cultivating a positive work environment. Our programs are meticulously crafted to amplify these core values most comprehensively. </div>
                           </li>
                           <li>
                              <div className=""> Two of our flagship initiatives, <strong> 'Women in Tech' </strong> and <strong> 'Career Vriddhi', </strong> are tailored to empower a significant segment of our society - our remarkable <strong>women</strong> . Sharing a similar vision, Skillible acknowledges the transformative power of <strong> Advanced Technology </strong> in transitioning today's learners from theoretical understanding to practical application. </div>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="why-our-section testimonial-candidate mb-4">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="newhding2 mt-5 text-center">
                        <span className="startIconX"><img src={require(`../assets/images/half-circle.png`)} className="img-fluid" alt="half-circle-icon" /></span>
                        <span className="heartIconX"> <img src={require(`../assets/images/arrow.png`)} className="img-fluid" alt="arrow-icon" /></span>
                        <span className="thimbIconX"> <img src={require(`../assets/images/thumb-icon.png`)} className="img-fluid" alt="thumb-icon" /></span>
                        <h2>
                           From Learners To Achievers
                        </h2>
                     </div>
                     <div className="owl-carousel owl-theme slider-for-business dottdsn dottdsn-fxtst" id="testimon-home">
                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/sowthamini.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>With a master's degree in computer application and 3 years of software engineering experience,
                                                she took a career break in 2014 due to a medical emergency. Discovering Skillible through social
                                                media, she embraced no-cost training, excelling in Salesforce development. Her performance led
                                                to her selection for an upskilling program, resulting in her employment as a Software Engineer
                                                at Neer Info Solution.
                                             </p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti"> Software Developer <span>Sowthamini Subramanian</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/hiral.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Holding a master's degree in computer science and having accumulated over 10 years of experience as an assistant professor,
                                                her career took an unexpected turn in 2018. Her journey led her to the digital landscape through LinkedIn, where Skillible emerged as a valuable find. She appreciated the Salesforce training and scholarships it offered, particularly the sessions conducted by Laxmi Narayan Pandey and Mr. Vikas Gupta, which kindled her interest. Despite encountering challenges,
                                                her unwavering determination guided her to her current position as a technical instructor at Salesforce.
                                             </p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti"> Hiral Kanani<span> Salesforce Developer</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="tree-plant sce991">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="text-center">
                        <h2>By planting a tree for each student enrolled,<br /> Skillible promises to protect the environment. </h2>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   )
}