import React, { useEffect, useState } from "react";
import bannerimg from "../assets/images/banner/slider-1-business.jpg"
import bannerimg7 from "../assets/images/banner/partner1.jpg"
import ReactOwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Slider() {
  const [option, setOption] = useState("");
  useEffect(() => {
    const options = {
      items: 1,
      dots: true,
    };

    setOption(options);

  }, []); 

 

  return (
    <section className="banner-home home-slider-skil">
      <ReactOwlCarousel {...option} margin={8} autoplay={true}  >
        <div>
        <img src={bannerimg} className="img-fluid"
         alt="Develop A Skill Employees Value The Most with Skillible" />
        </div>
        <div>
        <img src={bannerimg7} className="img-fluid" alt="Develop A Skill Employees Value The Most with Skillible" />
        </div>
      </ReactOwlCarousel>
    </section>
  )
}

export default Slider;