import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ApplyNowForm from './ApplyNowForm';
import { Link } from "react-router-dom";
import HeaderHome from './HeaderHome';
import ReactOwlCarousel from 'react-owl-carousel';
export default function BusinessAdministration() {
   const [modalShow, setModalShow] = useState(false);
   const [option, setOption] = useState("");
   useEffect(() => {
      const options = {
         items: 1,
         dots: true,
      };

      setOption(options);

   }, []);
   const handleModalShow = () => {
      setModalShow(true);
   }
   const handleModalClose = () => {
      setModalShow(false);
   }
   return (
      <React.Fragment>
         <HeaderHome />
         <HelmetProvider>
            <Helmet>
               <meta charset="UTF-8" />
               <title>Simplify Your Tech Hiring Needs With Skillible</title>
               <meta name="description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
               <link rel="canonical" href="http://skillible.io/" />
               <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
               <meta property="og:description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
               <meta property="og:url" content="http://skillible.io/" />
               <meta property="og:type" content="website" />
               <meta property="og:locale" content="en_US" />
               <meta property="og:site_name" content="Skillible" />
            </Helmet>
         </HelmetProvider>
         <div>
            <section className="banner-home home-slider-skil">
               <ReactOwlCarousel {...option} margin={8} autoplay={true}  >
                  <div>
                     <img src={require(`../assets/images/banner/business-to-admin.jpg`)} className="img-fluid"
                        alt="Develop A Skill Employees Value The Most with Skillible" />
                  </div>
                  <div>
                     <img src={require(`../assets/images/banner/sambhav2.png`)} className="img-fluid" alt="Develop A Skill Employees Value The Most with Skillible " />
                  </div>
               </ReactOwlCarousel>

            </section>
            <section className="services-industry">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center hding2 b-to-a-course">
                        <h2> Get the Scoop:   <span>Dive into Course Deets ! </span></h2>
                     </div>
                  </div>
               </div>
            </section>
            <section className="services-industry2">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6 col-lg-4 text-center">
                        <div className="services-in">
                           <span className="trans-img">
                              <img src={require(`../assets/images/genAI.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Always In Demand - Skillible" /></span>
                           <div className="services-intext virtual-inter">
                              <h3 className='genai-link'>
                                 <Link to="/genai"> AI Wiz</Link>
                              </h3>
                              <p className='genai-readmore'> <Link to="/genai">
                                 Level up your AI skills with Gen AI! This course cracks open the world of AI, teaching you the coolest
                                 tech from machine learning to chatbots. Get hands-on with projects and unleash the power
                                 of AI to change the game! <span className='genaiwiz-readmore'><img src={require(`../assets/images/readmore.png`)} className="img-fluid" alt="" /> </span>
                              </Link> </p>
                              <button className="btn enroll-now">
                                 <Link to="#" className="active" onClick={handleModalShow}  >
                                    Enroll Now
                                 </Link>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4 text-center">
                        <div className="services-in">
                           <span className="trans-img">
                              <img src={require(`../assets/images/cybersecurity.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Higher Productivity Rate - Skillible" /></span>
                           <div className="services-intext virtual-inter">
                              <h3>Cyber Skilled </h3>
                              <p className='cyberskillidtext'>Want to be a cybersecurity superhero? Our course teaches you the essentials to fight cybercrime! Learn about networks, encryption, and stopping online threats. With hands-on exercises, you'll be a data defender in no time. Become a guardian of the digital world with Skillible! </p>
                              <button className="btn enroll-now">
                                 <Link to="#">
                                    Coming Soon
                                 </Link>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-4 text-center">
                        <div className="services-in">
                           <span className="trans-img">
                              <img src={require(`../assets/images/salesforce-dev-program.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Excellent Remuneration - Skillible" /></span>
                           <div className="services-intext virtual-inter">
                              <h3>The Cloud Crafters</h3>
                              <p className='cyberskillidtext'>Level up your Salesforce skills with Skillible! This program dives into the platform, teaching you to build and customize like a pro. From the basics to advanced solutions, you'll gain the hands-on experience to become a Salesforce rockstar – developer or admin, you choose!
                              </p>
                              <button className="btn enroll-now">
                                 <Link to="#">
                                    Coming Soon

                                 </Link>
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="hw_bg_blue hw_second_heading mb-4">
               <div className="container">
                  <div className="col-md-12">
                     <div className="hw_payment_head">
                        <h3>Collaboration</h3>
                     </div>
                  </div>
                  <div className="hw_white_bg">
                     <div className="row">
                        <div className="col-md-6">
                           <div className="hw_pay_img"><img src={require(`../assets/images/how_it_works_img5.png`)} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="col-md-6 collab-skillible">
                           <div className="hw_pay_content">
                              <h5> <span className="skillible-partner"> Skillible </span>  & <span className="aictc-partner"> AICTE </span>: Reimagining Tech Education in India </h5>
                              <div className="hw_payment_inner">
                                 <p>Skillible joins hands with AICTE to revolutionize technical education in India. Together, we're building a next-gen talent pool with industry-ready skills through Skillible's project-based learning, aligned perfectly with AICTE's focus on modern education methods. </p>
                              </div>
                              <img src={require(`../assets/images/partner-img.png`)} className="img-fluid" alt="" />

                           </div>

                           {/* <div className='text-center'>
                              <button className="btn collab-btn">
                                 <Link to="https://internship.aicte-india.org/" target='_blank'>
                                    Get Insights <i className="fa fa-hand-o-right"></i></Link>
                              </button>
                           </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="pair-sectiontext hd2 ndvt-3">
               <div className="container">
                  <div className="row displ-lrtd mx-wth">
                     <div className="col-md-12 col-lg-6 text-end">
                        <div className="frenceimg-pair advc-tec">
                           <div className="img-frence-pair">
                              <img src={require(`../assets/images/advanced-technology.png`)} className="img-fluid" alt="Hire trained candidates with Skillible" />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                        <div className="languapair-steps step1 ">
                           <h2>What Is the Importance of <span className="advanced-technology">  Advanced Technology </span> & How It Can Help Indian Students ? </h2>
                           <p>In today's world, advanced technology is no longer a niche – it's the engine driving every industry. For Indian students, mastering these cutting-edge fields like AI, data science, or cybersecurity unlocks a treasure trove of benefits. Training in these domains equips them with the skills to: </p>
                           <ul className='advance-tech'>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Land high-demand jobs </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Become a problem-solving ninja </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Future-proof their careers</div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Boost India's tech power </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="section program-overview">
               <div className="container">
                  <div className="row d-flex justify-content-center">
                     <div className="col-md-12 text-center">
                        <div className="heading_black mb-5">
                           <h5>Program Overview <br />
                              <span></span>
                           </h5>
                        </div>
                     </div>
                     <div className="col-md-4 program-overview-box">
                        <div className="text-center program-box-1">
                           <span className="program-overview-img">
                              <img src={require(`../assets/images/Industry-curriculum.png`)} className="img-fluid" alt="" />
                           </span>
                           <div className="">
                              <p className="">A Curriculum that aligns with industry best practices
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4 program-overview-box">
                        <div className="text-center program-box-1">
                           <span className="program-overview-img">
                              <img src={require(`../assets/images/practical-experience.png`)} className="img-fluid" alt="" />
                           </span>
                           <div className="">
                              <p className="">Acquire Skills with Practical Experience
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4 program-overview-box">
                        <div className="text-center program-box-1">
                           <span className="program-overview-img">
                              <img src={require(`../assets/images/grab-insights.png`)} className="img-fluid" alt="" />
                           </span>
                           <div className="">
                              <p className="">Grab exciting insights by Industry Experts
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="section-full p-t100 p-b90 site-bg-gray twm-bg-ring-wrap2 ready-to-take">
               <div className="twm-bg-ring-right"></div>
               <div className="twm-bg-ring-left"></div>
               <div className="container-fluid">
                  <div className="wt-separator-two-part width90pst">
                     <div className="row">
                        <div className="col-md-12 text-center our-capabli">
                           <div className="">
                              <h2>Ready to Take the Next Step? <span className='next-step'> See How  It Works in Action!</span> </h2>
                           </div>
                        </div>
                     </div>
                     <div className="section-content text-center">
                        <div className="twm-jobs-grid-wrap user-experinc">
                           <div className="row mb-4">
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/submit-appic.png`)} alt="Full stack developers candidates available at Skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Submit your application via the Skillible Portal or the <span className="aictc-partner"> AICTE </span> website.</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/email-confirm.png`)} alt="Full stack developers candidates available at Skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Receive an email confirming your registration.   </p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/learning-journey.png`)} alt="Salesforce Development Program  - skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Embark on your learning journey with us.</p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/training-jrny.png`)} alt="Enterprise mobility management with Skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Technical training coupled with practical experience commences.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/participate.png`)} alt="Full stack developers candidates available at Skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Participate in masterclasses led by industry experts.  </p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/mentorship.png`)} alt="Full stack developers candidates available at Skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Comprehensive mentorship </p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/skills.png`)} alt="Salesforce Development Program  - skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Skills Evaluation  </p>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                 <div className="twm-jobs-grid-style1  m-b30  d-flex justify-content-center user-exprnc">
                                    <div className="twm-media userjrny-btoa">
                                       <img src={require(`../assets/images/certificate.png`)} alt="Enterprise mobility management with Skillible" />
                                    </div>
                                    <div className="twm-mid-content">
                                       <p className="twm-job-address">Obtain a certificate upon completion of the virtual internship.</p>
                                    </div>
                                 </div>
                              </div>

                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ades-sec1 mb-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="bg2colr1">
                           <div className="gren-part1">
                              <div className="greenbgclr">
                                 <div className="curriculum-empowers">
                                    <h4>Our Curriculum Empowers You to Upskill in the Right Direction!   </h4>
                                 </div>
                                 <div className="cir-advnc">
                                    <ul className="">
                                       <li> <span className="icon"><i className="fas fa-cirle"></i></span> Dive into high-demand fields like GenAI, Cybersecurity, and Salesforce with our detailed curriculum.  </li>
                                       <li> <span className="icon"><i className="fas fa-cirle"></i></span> Gain theoretical knowledge and practical skills through our hands-on approach tailored to real-world scenarios.  </li>
                                       <li><span className="icon"><i className="fas fa-cirle"></i></span> Our courses provide the tools and resources necessary to excel in the tech industry.  </li>
                                       <li><span className="icon"><i className="fas fa-cirle"></i></span> Empower yourself to upskill effectively and succeed in today's competitive tech landscape. </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className="girlcntr-part1 text-center">
                              <span>
                                 <img src={require(`../assets/images/cir-emp.png`)} className="img-fluid" alt="Skillible offers best resources" />
                              </span>
                           </div>
                           <div className="gray-part1 gray-part2">
                              <div className="certification">
                                 <h4>Stay Ahead of The Curve with Skillible Certification!   </h4>
                              </div>
                              <span>
                                 <div className="cir-advnc2">
                                    <ul className="">
                                       <li><span className="icon"><i className="fas fa-cirle"></i></span> Stand out from the crowd with industry-recognized proof of your expertise. </li>
                                       <li><span className="icon"><i className="fas fa-cirle"></i></span> Gain practical experience through the course, making you job-ready from day one.  </li>
                                       <li><span className="icon"><i className="fas fa-cirle"></i></span> Show your commitment to professional development and wow your employers.  </li>
                                       <li><span className="icon"><i className="fas fa-cirle"></i></span> Pave the way for success in your chosen field with in-demand certifications. </li>
                                    </ul>
                                 </div>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <section className="faqScen faqScenbg mb-0 pt-5 pb-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="mb-4 mt-4"> Frequently Asked Questions </h2>
                     </div>
                     <div className="bg_tabsfaq1">
                        <div className="row">
                           <div className="col-md-3">
                              <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                 <button className="nav-link active mt-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                    <span className="imgs">
                                    <img src={require(`../assets/images/a1_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- General Questions" />
                                    <img src={require(`../assets/images/a1_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- General Questions" />
                                    </span>
                                    <span>General Questions</span>
                                 </button>
                                 <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                    <span className="imgs">
                                    <img src={require(`../assets/images/a2_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- Fees & Income" />
                                    <img src={require(`../assets/images/a2_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- Fees & Income" />
                                    </span>
                                    <span> Fees & Income </span>
                                 </button>
                                 <button className="nav-link mb-0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    <span className="imgs">
                                    <img src={require(`../assets/images/a3_black.png`)} className="img-fluid img1" alt="Skillible- Admission Queries" />
                                    <img src={require(`../assets/images/a3_white.png`)} className="img-fluid img2" alt="Skillible- Admission Queries" />
                                    </span>
                                    <span>Admission Queries</span>
                                 </button>
                              </div>
                           </div>
                           <div className="col-md-9">
                              <div className="tab-content general_questions" id="v-pills-tabContent">
                                 <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="general-s">
                                       <h3>General Questions</h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">

                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> Are these programs run in collaboration with AICTE?  </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>Indeed, these programs are run in collaboration with AICTE.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading2">
                                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">  How long will the program last?  </button>
                                                </h2>
                                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p> The general duration of the program is 6 weeks.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="general-s">
                                       <h3> Fees & Income  </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">Are these courses chargeable?  </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>No, there's no cost associated with this course.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="general-s">
                                       <h3>Application Questions </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">

                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading17">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="true" aria-controls="collapse17"> Who can enrol in this program?  </button>
                                                </h2>
                                                <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>Technical graduates in their 2nd or 3rd year are eligible to enroll in this program.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </section>
            {modalShow && <ApplyNowForm show={modalShow} hide={handleModalClose} />}
         </div>
      </React.Fragment>
   )
}